import { Link } from 'gatsby'
import React from 'react'
import './our-team.scss'
import loadable from '@loadable/component'
import ImageSlider from '../shared/ImageSlider'
import Marked from 'react-markdown'
const Fade = loadable(() => import('../transition/Fade'))

const OurTeam = (props) => {
  return (
    <div className="our-team-section d-lg-flex" id="common-pl">
      <div className="title-container">
        <p className="sub-title">Our</p>
        <Fade timeout={500}>
          <h2>Team</h2>
        </Fade>
        <Fade timeout={1000}>
          <p className="title-dec">
            <Marked parserOptions={{ commonmark: true}} source={props.data.frontmatter.our_team_description} />
          </p>
        </Fade>
        <Link className="project-btn mb-5 mb-lg-0" to="/our-team">
          MEET US
        </Link>
      </div>
      <ImageSlider gallery={props.data.frontmatter.sliderImage}/>

      <div className="float-img-container red-angle-container">
        <img loading="lazy" src="https://res.cloudinary.com/dhuii7xg2/image/upload/c_scale,f_auto,q_auto,w_auto/v1614325932/Group_40_ftwpsy.png" alt="red-angle" className="float-img" />
      </div>
      {/* <div className="float-img-container float-img-container-2">
        <img src="https://res.cloudinary.com/dhuii7xg2/image/upload/v1613138404/about/float-img-2_pqqat1.svg" className="float-img" />
      </div>
      <div className="float-img-container float-img-container-3">
        <img src="https://res.cloudinary.com/dhuii7xg2/image/upload/v1613138404/about/float-img-3_c6sxzt.svg" className="float-img" />
      </div>
      <div className="float-img-container float-img-container-4">
        <img src="https://res.cloudinary.com/dhuii7xg2/image/upload/v1613138403/about/float-img-1_nxr7bi.svg" className="float-img" />
      </div> */}
    </div>
  )
}

export default OurTeam
